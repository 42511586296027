const NumSectionlang = {
  en: {
    titles: [
      {
        title: "Number of Memorization Centers",
      },
      {
        title: "Number of Scholars in Memorization Centers",
      },
      {
        title: "Number of Students in Memorization Centers",
      },
      {
        title: "Number of Certified Individuals",
      },
    ]
    
  },
  ar: {
    titles: [
      {
        title: "عدد مراكز التحفيظ",
      },
      {
        title: "عدد المشايخ في مراكز التحفيظ",
      },
      {
        title: "عدد الطلاب في مراكز التحفيظ",
      },
      {
        title: "عدد المجازين والمجازات",
      },

    ],
  },
};

export default NumSectionlang;
