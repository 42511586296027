const Paynowlang = {
    ar: {
      payOrderNow: "ادفع طلبك الآن",
      paymentMethod: "طريقة الدفع",
      orderNumber: "رقم الطلب",
      totalOrder: "اجمالي الطلب",
      dinar: "دينار",
      enterBirthYear: "ادخل سنة الميلاد",
      birthYearPlaceholder: "أدخل سنة الميلاد (مثال: 1990)",
      enterPhoneNumber: "ادخل رقم الهاتف",
      phoneNumberPlaceholder: "رقم الهاتف",
      submit: "ارسال",
      sending: "جاري الإرسال...",
      enterOtpCode: "ادخل رمز التأكيد",
      otpCodePlaceholder: "ادخل رمز التأكيد",
      confirmPayment: "تأكيد الدفع",
      confirmingPayment: "جاري تأكيد الدفع...",
      processingPayment: "جاري دفع الطلب...",
      paymentSuccessful: "تم دفع الطلب بنجاح!",
      errorSendingDetails: "خطأ أثناء إرسال تفاصيل الدفع",
      otpRequired: "رمز التأكيد مطلوب",
      birthYearRequired: "سنة الميلاد مطلوبة",
      phoneNumberRequired: "رقم الهاتف مطلوب",
    },
    en: {
      payOrderNow: "Pay Your Order Now",
      paymentMethod: "Payment Method",
      orderNumber: "Order Number",
      totalOrder: "Total Order",
      dinar: "Dinar",
      enterBirthYear: "Enter Birth Year",
      birthYearPlaceholder: "Enter birth year (e.g., 1990)",
      enterPhoneNumber: "Enter Phone Number",
      phoneNumberPlaceholder: "Phone Number",
      submit: "Submit",
      sending: "Sending...",
      enterOtpCode: "Enter OTP Code",
      otpCodePlaceholder: "Enter OTP Code",
      confirmPayment: "Confirm Payment",
      confirmingPayment: "Confirming Payment...",
      processingPayment: "Processing Payment...",
      paymentSuccessful: "Payment Successful!",
      errorSendingDetails: "Error sending payment details",
      otpRequired: "OTP code is required",
      birthYearRequired: "Birth year is required",
      phoneNumberRequired: "Phone number is required",
    },
  };
  
  export default Paynowlang;
  
  