const Sidebarlang = {
    ar: {
        home: "الصفحة الرئيسية",
        myActivity: "نشاطاتى",
        contests: "المسابقـــات و دورات تدريبية",
        seminars: "الندوات و مؤتمرات",
        shop: "المتجر",
        sheikhs: "المشايخ",
        memorizationCenters: "مراكز التحفيظ",
        orderHistory: "عمليات الشراء",
        borrowHistory: "طلبات الاستعارة",
        settings: "الاعدادات",
        library: "المكتبة",
        digitalLibrary: "مكتبة رقمية",
        localLibrary: "مكتبة محلية",
        courses: "الدورات",
        recordedLectures: "محاضرات مسجلة",
        helpCenter: "مركز المساعدة",
      },
      en: {
        home: "Home",
        myActivity: "My Activities",
        contests: "Contests & Training Courses",
        seminars: "Seminars & Conferences",
        shop: "Shop",
        sheikhs: "Sheikhs",
        memorizationCenters: "Memorization Centers",
        orderHistory: "Purchase History",
        borrowHistory: "Borrow Requests",
        settings: "Settings",
        library: "Library",
        digitalLibrary: "Digital Library",
        localLibrary: "Local Library",
        courses: "Courses",
        recordedLectures: "Recorded Lectures",
        helpCenter: "Help Center",
      },
    };
    
    export default Sidebarlang;