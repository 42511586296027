const Orderconfirmlang = {
    ar: {
      orderConfirmation: "تأكيد الطلب",
      orderSummary: "ملخص الطلب",
      noItems: "لا يوجد عناصر",
      oneItem: "عنصر واحد",
      multipleItems: "عناصر",
      deliveryInfo: "معلومات التوصيل",
      loadingDeliveryInfo: "جاري تحميل معلومات التوصيل...",
      paymentMethod: "طريقة الدفع",
      creatingOrder: "جارٍ إنشاء الطلب...",
      createOrder: "إنشاء طلب",
      createOrderSuccess: "تم إنشاء الطلب بنجاح",
      createOrderFailure: "فشل في إنشاء الطلب",
      denar: "د.ل",
    },
    en: {
      orderConfirmation: "Order Confirmation",
      orderSummary: "Order Summary",
      noItems: "No items",
      oneItem: "1 item",
      multipleItems: "items",
      deliveryInfo: "Delivery Information",
      loadingDeliveryInfo: "Loading delivery information...",
      paymentMethod: "Payment Method",
      creatingOrder: "Creating order...",
      createOrder: "Create Order",
      createOrderSuccess: "Order created successfully",
      createOrderFailure: "Failed to create order",
      denar: "DL",
    },
  };
  
  export default Orderconfirmlang;
  