const NewCoursesSectionlang = {
    ar: {
        title12: "الدورات التدريبية الجديدة",
        description12: "شاهد الدورات التدريبية الجديدة التي تمت إضافتها.",
        noCoursesMessage: "لا يوجد دورات تدريبية تمت اضافتها ..",
        buttonText: "اشتر الآن",
        likedText: "أضف إلى المفضلة",
        price: "دينار",
      },
      en: {
        title12: "New Training Courses",
        description12: "Check out the new training courses that have been added.",
        noCoursesMessage: "No new training courses added yet.",
        buttonText: "Buy Now",
        likedText: "Add to Favorites",
        price: "Dinar",
      },
  };
  
  export default NewCoursesSectionlang;
  