const Toastlang = {
    ar: {
        welcome: 'مرحبا',
        passwordErrortoast: 'كلمة المرور غير صحيحة. يرجى المحاولة مرة أخرى.',
        inputError: 'حدث خطأ أثناء الإدخال. الرجاء المحاولة مرة أخرى.',
        registrationSuccess: 'تم تسجيلك بنجاح يمكنك الدخول للنظام الآن.',
        otpAlreadyUsed: 'الرمز مستخدم بالفعل قم بالضغط على زر اعادة ارسال',
        userAlreadyExists: 'هذا المستخدم مسجل بالفعل في النظام.',
        invalidOtp: 'الرمز انتهت صلاحيته قم بالضغط على زر اعادة ارسال',
        genericError: 'حدث خطأ أثناء العملية. الرجاء المحاولة مرة أخرى.',
        doneotp : 'تم ارسال الرمز بنجاح',
      },
      en: {
        welcome: 'Welcome',
        passwordErrortoast: 'The password is incorrect. Please try again.',
        inputError: 'An error occurred during input. Please try again.',
        registrationSuccess: 'Registration successful! You can now log in.',
        otpAlreadyUsed: 'The OTP has already been used. Please click the resend button.',
        userAlreadyExists: 'This user is already registered in the system.',
        invalidOtp: 'The OTP has expired. Please click the resend button.',
        genericError: 'An error occurred during the process. Please try again.',
        doneotp : 'otp has been sent successfully',
      },
    };
    
    export default Toastlang;