import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoSearchOutline } from "react-icons/io5";
import { CiShoppingCart, CiLogout, CiSettings } from "react-icons/ci";
import { BsBell } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import Notifications from "./Notifications";
import { Link } from "react-router-dom";
import { GrUnorderedList } from "react-icons/gr";
import { CiViewList } from "react-icons/ci";
import { baseurl } from "../helper/Baseurl";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "../context/TranslationContext"; 

const NavbarLogin = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const { translations, language } = useTranslation(); // Translations and language from context
  
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0); // State for unread notifications count
  const navigate = useNavigate();
  
  const sessionLanguage = sessionStorage.getItem('language') || 'ar'; // Get language from sessionStorage

  useEffect(() => {
    const fetchCartItemCount = async () => {
      try {
        const response = await axios.get(baseurl + "my-cart", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCartItemCount(
          response.data.books.length + response.data.courses.length
        );
      } catch (error) {
        console.error("Error fetching cart item count:", error);
      }
    };

    const fetchUnreadNotificationCount = async () => {
      try {
        const response = await axios.get(baseurl + "my-notifications", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const unreadCount = response.data[0].filter(
          (notification) => !notification.isRead
        ).length;
        setUnreadNotificationCount(unreadCount); // Counting unread notifications
      } catch (error) {
        console.error("Error fetching unread notification count:", error);
      }
    };

    fetchCartItemCount();
    fetchUnreadNotificationCount();

    const handleCartUpdated = () => {
      fetchCartItemCount();
    };

    window.addEventListener("cartUpdated", handleCartUpdated);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdated);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div
      className="flex items-center justify-between bg-white p-4"
      style={{ direction: sessionLanguage === 'ar' ? 'ltr' : 'rtl' }} // Apply RTL or LTR based on language
    >
      <div className="flex items-center">
        <button
          onClick={() => setShowNotifications(!showNotifications)}
          className="relative"
        >
          <BsBell className="text-gray-700 text-xl mr-4" />
          {unreadNotificationCount > 0 && (
            <span className="absolute top-[-5px] right-[12px] bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
              {unreadNotificationCount}
            </span>
          )}
          {showNotifications && <Notifications />}
        </button>
        <Link to="/ShoppingCart" className="relative">
          <CiShoppingCart className="text-gray-700 text-2xl mr-4" />
          {cartItemCount > 0 && (
            <span className="absolute top-[-3px] right-[14px] bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
              {cartItemCount}
            </span>
          )}
        </Link>
        <div className="relative">
          <FiUser
            className="text-gray-700 text-xl cursor-pointer"
            onClick={() => setShowUserMenu(!showUserMenu)}
          />
          {showUserMenu && (
            <div className="absolute mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
              <Link
                to="/settings"
                className=" px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-end"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
                <span> {translations.settings}</span>
                <CiSettings className="ml-2" />
              </Link>
              <Link
                to="/profile"
                className=" px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-end"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
               <span> {translations.profile}</span>
                <FiUser className="ml-2" />
              </Link>
              <Link
                to="/OrderHistory"
                className=" px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-end"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
               <span> {translations.order_history}</span>
               <GrUnorderedList className="ml-2" />
              </Link>
              <Link
                to="/borrowsHistory"
                className=" px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-end"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
               <span> {translations.borrows_history}</span>
               <CiViewList className="ml-2" />
              </Link>
              <button
                className=" w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-end"
                style={{ fontFamily: "Tajwal, sans-serif" }}
                onClick={handleLogout}
              >
               <span> {translations.logout}</span>
               <CiLogout className="ml-2" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className=" flex items-center mb-1"
        style={{ fontFamily: "Tajwal, sans-serif", direction: sessionLanguage === 'ar' ? 'rtl' : 'ltr' }} // Apply RTL or LTR based on language
      >
        <button
          className="p-2 bg-blues rounded-full md:block ml-5 lg:hidden"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <MenuIcon />
        </button>
        <h2 className="text-lg sm:text-xl  font-bold text-custom-orange">
          {" "}
          {translations.quranComplex}
        </h2>
      </div>
    </div>
  );
};

export default NavbarLogin;
