//export const baseurl =  "http://160.19.99.6:8989/api/"

// export const baseurl =  "http://172.22.11.9:8080/api/"

// export const baseurl =  "http://app.holyquran.ly:8080/api/"

 export const baseurl =  "https://app.holyquran.ly/api/"

 //export const baseurl =  "http://127.0.0.1:8080/api/"

