const Settingslang = {
    en: {
      editProfile: "Edit Profile",
      enterFirstName: "Enter First Name",
      enterLastName: "Enter Last Name",
      enterMobileNo: "Enter Mobile Number",
      enterStudentId: "Student ID",
      enterEmail: "Enter Email Address",
      saveChanges: "Save Changes",
      passwordChange: "Change Password",
      enterCurrentPassword: "Enter Current Password",
      enterNewPassword: "Enter New Password",
      confirmNewPassword: "Confirm New Password",
      passwordChangedSuccess: "Password has been successfully changed.",
      profileUpdatedSuccess: "Profile changes saved successfully.",
      profileUpdateError: "An error occurred while saving profile changes.",
      loading: "Loading...",
      warningFillAllFields: "Please fill in all fields.",
      warningPasswordsMismatch: "New password and confirmation do not match.",
      errorFetchProfile: "An error occurred while fetching user profile.",
    },
    ar: {
      editProfile: "تعديل بيانات المستخدم",
      enterFirstName: "ادخل الاسم الأول",
      enterLastName: "ادخل اللقب",
      enterMobileNo: "ادخل رقم الهاتف",
      enterStudentId: "رقم القيد",
      enterEmail: "ادخل البريد الالكتروني",
      saveChanges: "حـــفـــظ",
      passwordChange: "تغيير كلمة المرور",
      enterCurrentPassword: "ادخل كلمة المرور القديمة",
      enterNewPassword: "ادخل كلمة المرور الجديدة",
      confirmNewPassword: "ادخل تأكيد كلمة المرور الجديدة",
      passwordChangedSuccess: "تم تغيير كلمة المرور بنجاح.",
      profileUpdatedSuccess: "تم حفظ التغييرات بنجاح.",
      profileUpdateError: "حدث خطأ أثناء حفظ التغييرات.",
      loading: "جاري التحميل...",
      warningFillAllFields: "الرجاء ملء جميع الحقول.",
      warningPasswordsMismatch: "كلمة المرور الجديدة وتأكيد كلمة المرور الجديدة غير متطابقين.",
      errorFetchProfile: "حدث خطأ أثناء جلب بيانات المستخدم.",
    },
  };
  
  export default Settingslang;
  