const SectionFivelang = {
  en: {
    quotes: [
      {
        text: "He who learns knowledge, then acts upon it and conveys it to others, has completed the blessing, and whoever acts upon knowledge but does not teach has gone astray",
        author: "Imam Malik ibn Anas",
      },
      {
        text: "Learn knowledge, teach it to the people, and learn tranquility and composure",
        author: "Umar ibn al-Khattab",
      },
      {
        text: "Knowledge is what benefits, and what benefits is that which brings you closer to Allah",
        author: "Ibn Taymiyyah",
      },
    ],
  },
  ar: {
    quotes: [
      {
        text: "مَن تَعَلَّمَ العِلْمَ ثُمَّ عَمِلَ بِهِ وأَوْصَلَهُ إلى النَّاسِ فَقَدْ أَتَمَّ النِّعْمَةَ، ومَنْ عَمِلَ بِالعِلْمِ وَلَمْ يُعَلِّمْ فَقَدْ ضَلَّ الطَّرِيقَ",
        author: "الإمام مالك بن أنس",
      },
      {
        text: "تَعَلَّمُوا العِلْمَ وَعَلِّمُوهُ النَّاسَ، وتَعَلَّمُوا الوَقَارَ والسُّكُونَ",
        author: "عُمَرُ بنُ الخَطَّابِ",
      },
      {
        text: "العِلْمُ مَا كَانَ نَافِعًا، والنَّافِعُ مَا يُقَرِّبُ إلى اللهِ",
        author: "ابن تيمية",
      },
    ],
  },
  };
  
  export default SectionFivelang;