const ContestsDetailslang = {
  ar: {
    organizer: "المنظم",
    address: "العنوان",
    applyStartDate: "تاريخ بدء التسجيل",
    applyEndDate: "تاريخ الانتهاء",
    papersReplayDate: "تاريخ مراجعة الأوراق",
    detailsTitle: "تفاصيل المسابقة",
    enrollmentEndDate: "تاريخ انتهاء التسجيل",
    contactMobile: "رقم الهاتف",
    contactEmail: "البريد الإلكتروني",
    notes: "ملاحظات",
    conditionsTitle: "شروط المسابقة",
    conditionInvalid: "تنسيق شرط غير صالح",
    courseDetails: "تفاصيل الدورة التدريبية",
    courseConditions: "شروط الدورة التدريبية",
    seminarDetails: "تفاصيل الندوة أو المؤتمر",
  seminarConditions: "شروط الندوة أو المؤتمر"
  },
  en: {
    organizer: "Organizer",
    address: "Address",
    applyStartDate: "Start Date of Registration",
    applyEndDate: "End Date",
    papersReplayDate: "Date of Papers Review",
    detailsTitle: "Contest Details",
    enrollmentEndDate: "Registration End Date",
    contactMobile: "Phone Number",
    contactEmail: "Email",
    notes: "Notes",
    conditionsTitle: "Contest Conditions",
    conditionInvalid: "Invalid condition format",
    courseDetails: "Course Details",
    courseConditions: "Course Conditions",
    seminarDetails1: "Seminar or Conference Details",
     seminarConditions: "Seminar or Conference Conditions"
  },
};

export default ContestsDetailslang;
