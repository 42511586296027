const notificationslang = {
    en: {
      notifications: {
        title: "Notifications",
        noNotifications: "No notifications yet...",
        newNotification: "New Notification",
        notificationDetails: "Details",
        time: "Time",
        viewAll: "View All Notifications"  
      }
    },
    ar: {
      notifications: {
        title: "الإشعارات",
        noNotifications: "لا يوجد إشعارات بعد ...",
        newNotification: "إشعار جديد",
        notificationDetails: "التفاصيل",
        time: "الوقت",
        viewAll: "عرض كل الإشعارات" 
      }
    }
  };
  
  export default notificationslang;
  