const ShiekhsListlang = {
  en: {
    shiekhsListTitle: "List of Sheikhs",
    shiekh1: {
      firstName: "Al-Sadiq",
      lastName: "Al-Ghiryani",
      phoneNumber: "0912345678",
      description: "A religious scholar specializing in Islamic jurisprudence and Sharia, with in-depth studies in Islamic sciences."
    },
    shiekh2: {
      firstName: "Ahmed",
      lastName: "Al-Tayeb",
      phoneNumber: "0923456789",
      description: "One of the top scholars of Al-Azhar and prominent religious figures in the Arab world."
    },
    shiekh3: {
      firstName: "Mohammed Bin Saleh",
      lastName: "Al-Othaimeen",
      phoneNumber: "0934567890",
      description: "One of the senior scholars in Saudi Arabia and one of the most prominent figures in Islamic jurisprudence."
    },
    shiekh4: {
      firstName: "Abdullah Bin Abdul Aziz",
      lastName: "Bin Baz",
      phoneNumber: "0945678901",
      description: "He was the Grand Mufti of Saudi Arabia for many years and issued many fatwas."
    },
    shiekh5: {
      firstName: "Saleh Bin Fawzan",
      lastName: "Al-Fawzan",
      phoneNumber: "0956789012",
      description: "A Saudi Islamic scholar with many books on Islamic jurisprudence and the fundamentals of religion."
    },
    viewMoreButton: "View More"
  },
  ar: {
    shiekhsListTitle: "قائمة المشايخ",
    shiekh1: {
      firstName: "الصادق",
      lastName: "الغرياني",
      phoneNumber: "0912345678",
      description: "عالم دين متخصص في الفقه والشريعة الإسلامية، وله دراسات معمقة في العلوم الشرعية."
    },
    shiekh2: {
      firstName: "أحمد",
      lastName: "الطيب",
      phoneNumber: "0923456789",
      description: "أحد أكبر علماء الأزهر الشريف ورجال الدين البارزين في العالم العربي."
    },
    shiekh3: {
      firstName: "محمد بن صالح",
      lastName: "العثيمين",
      phoneNumber: "0934567890",
      description: "من كبار العلماء في السعودية وواحد من أبرز العلماء في مجال الفقه الإسلامي."
    },
    shiekh4: {
      firstName: "عبد الله بن عبد العزيز",
      lastName: "بن باز",
      phoneNumber: "0945678901",
      description: "كان مفتي المملكة العربية السعودية لعدة سنوات وله العديد من الفتاوى."
    },
    shiekh5: {
      firstName: "صالح بن فوزان",
      lastName: "الفوزان",
      phoneNumber: "0956789012",
      description: "هو عالم إسلامي سعودي وله العديد من الكتب في الفقه وأصول الدين."
    },
    viewMoreButton: "عرض المزيد"
  }
};

export default ShiekhsListlang;
