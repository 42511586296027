const NavbarLoginlang = {
    ar: {
      settings: "الإعدادات",
      profile: "الملف الشخصي",
      order_history: "قائمة الطلبات",
      borrows_history: "طلبات الاستعارة",
      logout: "تسجيل خروج",
      quranComplex: "مجمع القرآن الكريم",

    },
    en: {
      settings: "Settings",
      profile: "Profile",
      order_history: "Order History",
      borrows_history: "Borrow History",
      logout: "Logout",
      quranComplex: "Holy Quran Complex",

     
    },
  };
  
  export default NavbarLoginlang;
  