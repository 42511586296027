const MyBookslang = {
    ar: {
        myBooks: "كتبي",
        MyCourses:"دوراتي",
        wishlist: "قائمة الرغبات",
        archive: "الأرشيف",
        libraryTitle: "مكتبتي",
        continueReading: "مواصلة قراءة الكتب",
        continueReadingCourses: "مواصلة مشاهدة الدورات",
        noPurchasedBooks: "لا يوجد كتب قمت بشراءها .. قم بالشراء الآن",
        noLikedBooks: "لا يوجد كتب قمت بالإعجاب بها ..",
        noCompletedBooks: "لا يوجد كتب قمت بأكمال قراءتها .. اكمل قراءة كتبك الان", 
        libraryTitle: "مكـــتبــتي",
        borrowedBooks: "الكــتـب مــحليـــة التى تمت استعارتها",
        borrowedBooksButton: "كتب المستعارة",
        borrowedBooksWarning: "لديك كتب مستعارة يجب ارجاعها", 
        noBorrowedBooks: "لا يوجد كتب قمت بأستعارتها من المكتبة ..", 
        returnDate: "تاريخ الإرجاع: ", 
        CoursesProgress:"تقدم الدورة",
        noRegisteredCourses: "لا يوجد دورات مسجل بها حاليا. قم بالاشتراك في الدورات", 
        noLikedCourses: "لا يوجد دورات قمت بالإعجاب بها ..", 
        userName: "اسم المستخدم",
        email: "البريد الالكتروني",
        phoneNumber: "رقم الهاتف",
        accountType: "نوع الحساب",
        myBooksSection: "الكتب والدورات الخاصة بالطالب:",
        myBooksButton: "الكتب الخاصة بك",
        myCoursesButton: "الكورسات الخاصة بك",
        ArchiveButtoncourses:"      لا يوجد دورات انهيت مشاهداتها .. شاهد دوراتك الان"


    },
    en: {
        myBooks: "My Books",
        wishlist: "Wishlist",
        archive: "Archive",
        libraryTitle: "My Library",
        continueReading: "Continue Reading Books",
        noPurchasedBooks: "You have not purchased any books.. Buy now",
        noLikedBooks: "You have not liked any books..",
        noCompletedBooks: "You have not completed reading any books.. Continue reading now", 
        libraryTitle: "My Library",
        borrowedBooks: "Local books that have been borrowed",
        borrowedBooksButton: "Borrowed Books",
        borrowedBooksWarning: "You have borrowed books that need to be returned", 
        noBorrowedBooks: "You have not borrowed any books from the library..", 
        returnDate: "Return Date: ", 
        MyCourses:"My Courses",
        continueReadingCourses: "Continue Watching Courses",
          CoursesProgress:"Courses Progress ",
          noRegisteredCourses: "You are not registered for any courses currently. Enroll in courses",
          noLikedCourses: "You have not liked any courses..",
          userName: "Username",
          email: "Email",
          phoneNumber: "Phone Number",
          accountType: "Account Type",
          myBooksSection: "Books and Courses for student",
          myBooksButton: "Your Books",
          myCoursesButton: "Your Courses",
ArchiveButtoncourses: "There are no courses you have finished watching. Start watching your courses now!"
    },
};

export default MyBookslang;
