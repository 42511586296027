

const LoginRegisterlang = {
    en: {
      platformWelcome: 'Welcome to the educational platform',
      continueWatching: 'Continue watching the courses you have already started',
      lastName: 'Last Name',
      firstName: 'First Name',
      accountType: 'Account Type',
      birthYear: 'Date of Birth',
      mobileNo: 'Phone Number',
      studentId: 'Student ID',
      password: 'Password',
      email: 'Email Address',
      otp: 'Verification Code',
      verify: 'Verify',
      resend: 'Resend',
      register: 'Register',
      loginPrompt: 'Already have an account? Log in now',
      loginPrompt1: 'Already have an account?',
      loginnow:"Log in now",
      internalStudent: 'Internal Student',
      externalStudent: 'External Student',
      public: 'Public',
      instructor: 'Instructor',
      placeholderEmail: 'email@example.com',
      placeholderPhone: 'Phone Number',
      placeholderPassword: '********',
      otpLabel: 'Verification Code',
      placeholderotpLabel: 'add verification code',
    resendButton: 'Resend',
    submitButton: 'Submit',
    verifyButton: 'Verify',
    emailError1: 'Please enter a email address.',
    emailError: 'Please enter a valid email address.',
    firstNameError: 'Please enter your first name.',
    firstNamevalid: 'the name should be characters only',
    lastNamevalid: 'the name should be characters only',
    lastNameError: 'Please enter your last name.',
    birthYearError: 'Please enter your date of birth.',
    learnerTypeError: 'Please select an account type.',
    passwordError: 'Please enter your password.',
    passwordLengthError: 'Password should be at least 6 characters.',
    mobileNoError: 'Please enter a valid phone number.',
    otpError: 'Please enter a valid OTP (6 digits).',
    otpenter: 'Please enter  OTP .',
    Forgotpass:"Forgot your password?"
    },
    ar: {
      platformWelcome: 'مرحبًا بك في المنصة التعليمية',
      continueWatching: 'استمر في مشاهدة الدورات التدريبية التي بدأت مشاهدتها بالفعل',
      lastName: 'اللقب',
      firstName: 'الاسم',
      accountType: 'نوع الحساب',
      birthYear: 'تاريخ الميلاد',
      mobileNo: 'رقم الهاتف',
      studentId: 'رقم القيد',
      password: 'كلمة المرور',
      email: 'عنوان البريد الإلكتروني',
      otp: 'رمز التحقق',
      verify: 'تحقق',
      resend: 'إعادة إرسال',
      register: 'تسجيل',
      loginPrompt: 'لديك حساب؟ قم بتسجيل الدخول الآن',
      loginPrompt1: 'هل لديك حساب؟',
loginnow: 'قم بتسجيل الآن',

      internalStudent: 'طالب داخلي',
      externalStudent: 'طالب خارجي',
      public: 'عام',
      instructor: 'مدرب',
      placeholderEmail: 'email@example.com',
      placeholderPhone: 'رقم الهاتف',
      placeholderPassword: '********',
      otpLabel: 'رمز التحقق',
      placeholderotpLabel: 'قم بادخال رمز التحقق',
      resendButton: 'إعادة إرسال',
      submitButton: 'تسجيل',
      verifyButton: 'تحقق',
      emailError1: 'الرجاء إدخال بريد إلكتروني ',
      emailError: 'الرجاء إدخال بريد إلكتروني صالح',
      firstNameError: 'الرجاء إدخال الاسم',
      firstNamevalid: 'الاسم يجب ان يتكون من حروف فقط',
      lastNamevalid: 'الاسم يجب ان يتكون من حروف فقط',
      lastNameError: 'الرجاء إدخال اللقب',
      birthYearError: 'الرجاء إدخال تاريخ الميلاد',
      learnerTypeError: 'الرجاء اختيار نوع الحساب',
      passwordError: 'الرجاء إدخال كلمة المرور',
      passwordLengthError: 'يجب أن تكون كلمة المرور على الأقل 6 أحرف',
      mobileNoError: 'الرجاء إدخال رقم هاتف صالح',
      otpError: 'الرجاء إدخال رمز التحقق صالح (6 أرقام)',
      otpenter: 'ادخل رمز التحقق',
      Forgotpass:'هل نسيت كلمة المرور ؟'
    },
  };
  export default LoginRegisterlang