const Footerlang = {
    en: {
      logoTitle: "The Quran Complex",
      logoDescription: 
        "A complex dedicated to supervising the teaching of the Quran, studying its sciences, conducting in-depth studies about it, showcasing its purposes, achieving its objectives, and highlighting its knowledge and guidance.",
      servicesTitle: "Our Online Services",
      services1: [
        "Teaching recitation and tajweed",
        "Teaching memorization",
        "Teaching tafsir",
        "Teaching grammar",
        "Teaching Quranic readings",
        "Teaching similar verses"
      ],
      contactTitle: "Contact Us!",
      location: "Zliten, Libya",
      phone: "+218-2100000",
      email: "info@hqcl.gov.ly",
      copyright: "All rights reserved",
      year: "2025",
      complexName: "The Quran Complex",
    },
    ar: {
      logoTitle: "مجمع القرآن الكريم",
      logoDescription: 
        "مَجْمَعُ يُعني بالإشراف على تعليم القرآن الكريم، ودراسة علومه، وإقامة الدراسات المُعمَقة عنه، إظهارا لمقاصده، وتحقيقا لأهدافه، وإبرازا لمعارفه وهداياته.",
      servicesTitle: "خدماتنا الالكترونية",
      services1: [
        "تعليم التلاوة والتجويد",
        "تعليم الحفظ",
        "تعليم التفسير",
        "تعليم الإعراب",
        "تعليم القراءات",
        "تعليم المتشابهات"
      ],
      contactTitle: "تواصل معنا!",
      location: "زليتن، ليبيا",
      phone: "218-2100000+",
      email: "info@hqcl.gov.ly",
      copyright: "جميع الحقوق محفوظة",
      year: "2025",
      complexName: "مجمع القرآن الكريم",
    },
  };
  
  export default Footerlang;
  