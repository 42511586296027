

const FAQSectionlang = {
  en: {
    faqTitle: "Frequently Asked Questions",
    questions: [
      {
        question: "Is it required to pay any fees for registration?",
        answer: "No, registration is free and available to everyone.",
      },
      {
        question: "Can I memorize the Quran with you, or is it only recitation?",
        answer: "Yes, you can memorize the Quran with us or only recite it.",
      },
      {
        question: "I have not studied Tajweed rules before; can I join?",
        answer: "Of course, we provide simplified lessons suitable for all levels.",
      },
      {
        question: "Are your schools specialized in Tajweed rules?",
        answer: "Yes, all our schools offer specialized lessons in Tajweed rules.",
      },
    ],
  },
  ar: {
    faqTitle: "الاسئلة الشائعة",
    questions: [
      {
        question: "هل يشترط دفع رسوم معينة مقابل التسجيل؟",
        answer: "لا يشترط دفع رسوم للتسجيل. التسجيل مجاني ومتاح للجميع.",
      },
      {
        question: "هل أستطيع أن أحفظ القرآن معكم أم تلاوة فقط؟",
        answer: "نعم، يمكنك حفظ القرآن الكريم معنا أو الاكتفاء بالتلاوة فقط.",
      },
      {
        question: "لم أدرس أحكام التجويد من قبل، فهل يمكنني الالتحاق؟",
        answer: "بالطبع، نوفر دروسًا مبسطة تناسب جميع المستويات.",
      },
      {
        question: "هل مدارسكم مخصصة لأحكام التجويد؟",
        answer: "نعم، جميع مدارسنا تقدم دروسًا متخصصة في أحكام التجويد.",
      },
    ],
  },
};

export default FAQSectionlang;
