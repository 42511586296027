const SecondSectionlang = {
    en: {
      welcomeTitle: "Welcome to Our Complex",
      welcomeText:
        "In fulfillment of the vision of His Highness Sheikh – may God preserve him – and striving for excellence and leadership in serving the Holy Quran and its sciences, the idea of establishing the Holy Quran Complex in Libya came to be a distinguished Quranic scientific beacon.",
      features: [
        {
          title: "Global Electronic Quran Recitation Hub",
        },
        {
          title: "Quranic Manuscripts",
        },
        {
          title: "Virtual Tour",
        },
      ],
    },
    ar: {
      welcomeTitle: "أهلاً بكم في مجمعنا",
      welcomeText:
        "تحقيقاً لرؤية صاحب السمو الشيخ -حفظه الله-، وسعياً نحو التميز والريادة في خدمة القرآن الكريم وعلومه، جاءت فكرة إنشاء مجمع القرآن الكريم بليبيا ليكون منارة علمية قرآنية متميزة.",
      features: [
        {
          title: "مقرأة ليبيا الإلكترونية العالمية",
        },
        {
          title: "المخطوطات القرآنية",
        },
        {
          title: "الجولة الافتراضية",
        },
      ],
    },
  };
  
  export default SecondSectionlang;
  