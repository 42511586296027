const ThirdSectionlang = {
  en: {
    hadith: "The Messenger of Allah, peace be upon him, said",
    quote: "The best among you are those who learn the Quran and teach it",
    hadithSource: "Sahih al-Bukhari",
  },
  ar: {
    hadith: "قال رسول الله صلى الله عليه وسلم",
    quote: "خَيْرُكُمْ مَنْ تَعَلَّمَ الْقُرْآنَ وَعَلَّمَهُ",
    hadithSource: "صحيح البخاري",
  },
  };
  
  export default ThirdSectionlang;