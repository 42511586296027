const Serviceslang = {
  en: {
    title: "Learn About Our Services",
    services2: [
      {
        id: 1,
        title: "Monitoring and Caring for Quran Memorization Centers",
        description: "Monitoring and caring for Quran memorization centers and organizing educational and training courses for the memorizers to enhance their academic level.",
      },
      {
        id: 2,
        title: "Granting Accredited Certificates in Quran Memorization",
        description: "Granting accredited certificates in Quran memorization with its mutawatir readings by organizing ijazah exams supervised by senior memorizers and reciters.",
      },
      {
        id: 3,
        title: "Organizing Seminars and Conferences",
        description: "Organizing seminars and conferences that serve the Quran and its sciences, and publishing specialized studies and research in this field.",
      },
      {
        id: 4,
        title: "Holding Local and International Competitions",
        description: "Holding local and international competitions in Quran memorization, Tajweed, and Tafseer.",
      },
      {
        id: 5,
        title: "Printing the Holy Quran with its Mutawatir Readings",
        description: "Focusing on printing the Holy Quran with its mutawatir readings.",
      },
    ],
  },
  ar: {
    title: "تعرف عن خدماتنا",
    services2: [
      {
        id: 1,
        title: "متابعة مراكز تحفيظ القرآن الكريم ورعايته",
        description: "متابعة مراكز تحفيظ القرآن الكريم ورعايتها والعمل على إقامة الدورات العلمية والتربوية للمحفظين: للارتقاء بمستواهم العلميّ",
      },
      {
        id: 2,
        title: "منح شهادات معتمدة في حفظ القرآن الكريم",
        description: "منح شهادات معتمدة في حفظ القرآن الكريم برواياته المتواترة من خلال إقامة امتحانات الإجازة التي يشرف عليها كبار الحفاظ والمقرئين",
      },
      {
        id: 3,
        title: "عقد الندوات والمؤتمرات",
        description: "عقد الندوات والمؤتمرات التي تخدم القرآن الكريم وعلومه، ونشر الدراسات والبحوث العلمية المتخصصة في هذا المجال.",
      },
      {
        id: 4,
        title: "إقامة المسابقات المحلية والدولية",
        description: "إقامة المسابقات المحلية والدولية في حفظ القرآن الكريم وتجويده وتفسيره.",
      },
      {
        id: 5,
        title: "الاهتمام بطباعة المصحف الشريف برواياته المتواترة",
        description: "الاهتمام بطباعة المصحف الشريف برواياته المتواترة.",
      },
    ],
  },
  };
  
  export default Serviceslang;