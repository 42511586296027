

  const Navbarlang = {
    en: {
      welcome: "Welcome",
      account: "Account",
      register: "Register",
      login: "Login",
      contactUs: "Contact Us",
      sheikhs: "Sheikhs",
      services: "Our Services",
      home: "Home",
      quranComplex: "Holy Quran Complex",
      quranComplexlibya:"Holy Quran Complex libya",
      Description:"The Holy Quran Complex is a government institution dedicated to the Quran and its sciences, focusing on education, authorship, authentication, and publication. It preserves Islamic identity and uplifts the Muslim community by combining the principles of tradition with the requirements of modern technology.",
      registernow:"Register Now"
      
    },
    ar: {
      welcome: "مرحبًا",
      account: "حسابي",
      register: "الاشتراك",
      login: "تسجيل الدخول",
      contactUs: "اتصل بنا",
      sheikhs: "المشايخ",
      services: "خدماتنا",
      home: "الصفحة الرئيسية",
      quranComplex: "مجمع القرآن الكريم",
      quranComplexlibya:"مجمع القرآن الكريم في ليبيا",
      Description:"هو مؤسسة حكومية تُعنى بالقرآن الكريم وعلومه تعليما وتأليفا وتحقيقاً ونشراً، وتُحافظ على الهُويّة الإسلامية،  وترتقي بالمجتمع المسلم عبر وسائل نُزاوج بين ثوابت الأصالة ومقتضيات التقنية المعاصرة",
      registernow:"سجل معنا الان"
    },
  };
  
  export default Navbarlang;
  