const Paytheorderlang = {
    ar: {
      pageTitle: "دفع الطلب",
      orderSummary: "ملخص الطلب",
      userInfo: "معلومات المستخدم",
      loadingUserInfo: "جاري تحميل معلومات التوصيل...",
      address: "العنوان",
      city: "المدينة",
      name: "الاسم",
      mobileNo: "رقم الهاتف",
      orderNo: "طلب رقم",
      orderDate: "تاريخ الطلب",
      orderStatus: "حالة الطلب",
      totalAmount: "إجمالي الطلب",
      paymentType: "نوع الدفع",
      discount: "الخصم",
      loadingOrderDetails: "جاري تحميل تفاصيل الطلب...",
      payNow: "ادفع الان",
      payingNow: "جارٍ ادفع الان...",
      denar: "د ل",
    },
    en: {
      pageTitle: "Pay the Order",
      orderSummary: "Order Summary",
      userInfo: "User Information",
      loadingUserInfo: "Loading delivery information...",
      address: "Address",
      city: "City",
      name: "Name",
      mobileNo: "Mobile Number",
      orderNo: "Order Number",
      orderDate: "Order Date",
      orderStatus: "Order Status",
      totalAmount: "Total Amount",
      paymentType: "Payment Type",
      discount: "Discount",
      loadingOrderDetails: "Loading order details...",
      payNow: "Pay Now",
      payingNow: "Processing payment...",
      denar: "DL",
    },
  };
  
  export default Paytheorderlang;
  