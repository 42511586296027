const Contestslang = {
    ar: {
        contestsAndCourses: "المسابقات و الدورات التدريبية",
        allContests: "جميع المسابقات",
        trainingCourses: "الدورات التدريبية",
        errorFetchingContests: "حدث خطأ أثناء جلب بيانات .",
        registrationSuccess: "تم التسجيل بنجاح!",
        contestNotFound: "لم يتم العثور على المسابقة المطلوبة.",
        alreadyRegistered: "لقد قمت بالتسجيل مسبقًا!",
        registrationFailed: "فشل التسجيل. يرجى المحاولة مرة أخرى.",
        noContestsAvailable: "لا توجد مسابقات متاحة حالياً.",
        contestDate: "تاريخ المسابقة: ",
        courseDate: "تاريخ الدوره: ",
        registerNow: "سجل الان",
        contestDetails: "تفاصيل المسابقة",    
        courseDetails: "تفاصيل الدورة",
        modalTitle: "تسجيل في المسابقة", 
        modalTitlecourse: " تسجيل في دورة التدريبية",
        fullName: "الاسم الكامل",
        nationalityCode: "الرقم الوطني",
        gender: "الجنس",
        birthDate: "تاريخ الميلاد",
        email: "البريد الإلكتروني",
        mobileNo: "رقم الهاتف",
        attachmentFile: "ملف مرفق",
        city: "المدينة",
        subscriberNotes: "ملاحظات المشترك",
        registerButton: "تسجيل",
        closeButton: "إغلاق",
        noCoursesAdded: "لا يوجد دورات تدريبية تمت اضافتها في الوقت الحالى .."

    },
    en: {
      contestsAndCourses: "Contests and Training Courses",
      allContests: "All Contests",
      trainingCourses: "Training Courses",
      errorFetchingContests: "An error occurred while fetching contest data.",
      registrationSuccess: "Registration successful!",
      contestNotFound: "The requested contest could not be found.",
      alreadyRegistered: "You have already registered for this event!",
      registrationFailed: "Registration failed. Please try again later.",
      noContestsAvailable: "No contests available at the moment.",
      contestDate: "Contest Date: ",
      courseDate: "Course Date",
     registerNow: "Register Now",
     courseDetails: "Course Details",
    contestDetails: "Contest Details",
    modalTitle: "Register for Contest",
      modalTitle: "Register for Contest",
      fullName: "Full Name",
      nationalityCode: "National ID",
      gender: "Gender",
      birthDate: "Birth Date",
      email: "Email",
      mobileNo: "Mobile Number",
      attachmentFile: "Attachment File",
      city: "City",
      subscriberNotes: "Subscriber Notes",
      registerButton: "Register",
      closeButton: "Close",
      noCoursesAdded: "No training courses have been added at this time...",
      modalTitlecourse: "Register for the Training Course"
    }
  };
  
  export default Contestslang;
  