const Shoppingcartlang = {
    ar: {
      cart: "عربة الشراء",
      total: "الإجمالي",
      checkout: "إتمام الشراء",
      remove: "إزالة",
      clearCart: "حذف كل العناصر",
      emptyCart: "عربة الشراء فارغة",
      price: "السعر",
      author: "المؤلف",
      publisher: "دار النشر",
      proceedCheckout: "جاري إتمام الشراء...",
      noItems: "لا توجد عناصر في عربة التسوق",
    },
    en: {
      cart: "Shopping Cart",
      total: "Total",
      checkout: "Checkout",
      remove: "Remove",
      clearCart: "Clear All Items",
      emptyCart: "Shopping Cart is Empty",
      price: "Price",
      author: "Author",
      publisher: "Publisher",
      proceedCheckout: "Processing Checkout...",
      noItems: "No items in the shopping cart",
      
    },
  };
  
  export default Shoppingcartlang;
  